import * as React from "react";
import styled from "styled-components";
import { BsSearch, } from "react-icons/bs";
import { AiOutlineHeart } from "react-icons/ai";
import logoHorizontal from "@assets/img/logo.svg";
import logoSmall from "@assets/img/logo.png";
import { useWindowSize } from "@util/hooks";
import { Link, NavLink } from "react-router-dom";
import { useAppDispatch } from "@redux/index";
import { setSearchTerm } from "@redux/AppData.store";
import { useHistory } from "react-router-dom";

const SearchInputContainer = styled.div`
  border: 1px solid black;
  border-radius: 12px;
  margin-right: 10px;
  display: flex;
  align-items: center;
  background-color: white;
  min-width: 250px;
  padding-left: 10px;
  @media (max-width: 768px) {
    min-width: 0px;
  }
`;

const SearchInput = styled.input`
  border: none;
  margin-left: 00px;
  box-sizing: border-box;
  width: 100%;
  border-radius: 12px;
  padding: 5px 5px 5px 10px;
  height: 40px;
`;

const Menu = styled.div`
  width: auto;
  overflow-x: auto;
  overflow-y: hidden;
  white-space: nowrap;
  background-color: #9841e2;
  @media (min-width: 768px) {
    display: flex;
    justify-content: space-between;
  }
`;

const MenuLink = styled(NavLink)`
  text-decoration: none;
  margin-right: 20px;
  color: white;
  :hover {
    color: white;
    text-decoration: underline;
  }
`;

const MenuLinkAnchor = styled.a`
  color: white;
  text-decoration: none;
  margin-right: 20px;
  :hover {
    color: white;
    text-decoration: underline;
  }
`;

const TopBar: React.FC = () => {
  const { width } = useWindowSize();
  const dispatch = useAppDispatch();
  const history = useHistory();

  // Using a local search term, so the redux searchTerm is only updated when the input is submitted
  const [tempSearchTerm, setTempSearchTerm] = React.useState("");

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setTempSearchTerm(e.target.value);
  };

  const handleFormSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    dispatch(setSearchTerm(tempSearchTerm));
    // Redirect to home if user provide an empty field
    if (!tempSearchTerm) return history.replace("/");
    history.push(`/estabelecimentos?busca=${encodeURIComponent(tempSearchTerm)}`);
  };

  return (
    <div className="p-0">
      <div className="row px-4 px-lg-4 py-3 m-0">
        <Link to="/" className="col-auto p-0  d-flex flex-column justify-content-center align-items-center" style={{ textDecoration: "none" }}>
          <img src={width >= 768 ? logoHorizontal : logoSmall} height={width > 768 ? 65 : 40} alt="logo" />
        </Link>
        <div className="col d-flex justify-content-end p-0">
          <form className="d-flex justify-content-center justify-content-md-end align-items-center" onSubmit={handleFormSubmit}>
            <SearchInputContainer>
              <BsSearch />
              <SearchInput className="form-control-sm" placeholder="pesquise qualquer coisa" onChange={handleInputChange} />
            </SearchInputContainer>
            <Link to={`/favoritos`}>
              <AiOutlineHeart size={32} color="black" />
            </Link>
          </form>
        </div>
      </div>
      <div className="row m-0">
        <hr className="p-0 m-0" />
      </div>
      <div className="row m-0">
        <Menu className="col px-4 py-3">
          <MenuLink activeStyle={{ fontWeight: 900 }} exact to="/">
            Início
          </MenuLink>
          <MenuLink activeStyle={{ fontWeight: 900 }} to="/sobre">
            O Moda Center
          </MenuLink>
          <MenuLink activeStyle={{ fontWeight: 900 }} to="/calendario">
            Calendário das feiras
          </MenuLink>
          <MenuLink activeStyle={{ fontWeight: 900 }} to="/excursoes">
            Excursões/Transportadoras
          </MenuLink>
          <MenuLink isActive={() => window.location.href.includes(`hotel`)} activeStyle={{ fontWeight: 900 }} to="/estabelecimentos?stablishment_type=hotel">
            Hospedagem
          </MenuLink>
          <MenuLink isActive={() => window.location.href.includes(`restaurant`)} activeStyle={{ fontWeight: 900 }} to="/estabelecimentos?stablishment_type=restaurant">
            Alimentação
          </MenuLink>
          <MenuLink isActive={() => window.location.href.includes(`kiosk`)} activeStyle={{ fontWeight: 900 }} to="/estabelecimentos?stablishment_type=kiosk">
            Quiosques
          </MenuLink>
          <MenuLink activeStyle={{ fontWeight: 900 }} to="/faq">
            Perguntas frequentes
          </MenuLink>
          <MenuLinkAnchor href="http://www.blogdomodacenter.com.br/" target="_blank" rel="noreferrer noopener">
            Blog do Moda Center
          </MenuLinkAnchor>
          <MenuLinkAnchor href="http://f7byte.com/" target="_blank" rel="noreferrer noopener">
            Área do condômino
          </MenuLinkAnchor>
          <MenuLinkAnchor href="https://app.notadamoda.com/index.php?class=NfaList/" target="_blank" rel="noreferrer noopener">
            Emitir nota fiscal avulsa
          </MenuLinkAnchor>
        </Menu>
      </div>
    </div>
  );
};

export default TopBar;
