/* eslint-disable react-hooks/exhaustive-deps */
import { Department, Segment } from "@util/interfaces";
import * as React from "react";
import { useHistory } from "react-router";
import { toastError } from "@util/functions";
import Api from "@services/Api";
import { RootContainer, SegmentContainer, ScrollContainer, Title, Image } from "./styled";
interface SegmentsListProps {
  openDay: string;
}

const Segments: React.FC<SegmentsListProps> = ({ openDay }) => {
  const [segments, setSegments] = React.useState<Segment[]>([]);
  const [selectedDepartment, setSelectedDepartment] = React.useState<Department>();
  const history = useHistory();

  React.useEffect(() => {
    getSegments();
    setSelectedDepartment({} as Department);
  }, [openDay]);

  const getSegments = async () => {
    try {
      const { data } = await Api.get(`/segment?open_day=${openDay}`);
      setSegments(openDay !== "monday" && "sunday" ? data.segments : []);
    } catch (error) {
      toastError(error, "error");
    }
  };

  const handlePress = (segment: Segment) => {
    history.push("/segmento/" + segment.id);
  };

  const uniqueDepartments: Department[] = [];
  segments
    .map((segment) => segment.department as Department)
    .forEach((department) => (uniqueDepartments.map((dep) => dep.id).includes(department.id) ? null : uniqueDepartments.push(department)));

  const segmentsFromDepartments: Segment[] = segments.filter((segment) => segment.department?.id === selectedDepartment?.id);

  return (
    <div>
      <ScrollContainer>
        <RootContainer className="pb-2 px-3">
          {uniqueDepartments.map((department) => (
            <SegmentContainer
              key={department.id}
              onClick={() => {
                setSelectedDepartment(department);
              }}
            >
              <Image src={department.image_url} />
              <Title style={{ textAlign: "center" }}>{`${department.name.toLowerCase()}`}</Title>
            </SegmentContainer>
          ))}
        </RootContainer>
      </ScrollContainer>
      <ScrollContainer>
        <RootContainer className="pb-2 px-3">
          {segmentsFromDepartments.map((segment) => (
            <SegmentContainer
              key={segment.id}
              onClick={() => {
                handlePress(segment);
              }}
            >
              <Image src={segment.image_url} />
              <Title style={{ textAlign: "center" }}>{`${segment.name.toLowerCase()}\n${segment.department?.name?.toLowerCase() ?? ""}`}</Title>
            </SegmentContainer>
          ))}
        </RootContainer>
      </ScrollContainer>
    </div>
  );
};

export default Segments;
