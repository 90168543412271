import { Hotel, Phone } from "@util/interfaces";
import * as React from "react";
import Styled from "./styled";
import { FaWhatsapp } from "react-icons/fa";

type HotelListItemProps = {
  hotel: Hotel;
};

const HotelListItem: React.FC<HotelListItemProps> = ({ hotel }) => {
  const handlePhoneClick = (phone?: Phone) => {
    if (phone?.is_whatsapp) {
      window.open(
        `https://api.whatsapp.com/send?phone=+55${phone.number.replace(
          /\D+/g,
          ""
        )}&text=Olá, encontrei este hotel no aplicativo do Moda Center. Gostaria de mais informações sobre hospedagem.`
      );
    }
  };

  return (
    <Styled.RootContainer>
      <Styled.Title>{hotel.name.toUpperCase()}</Styled.Title>
      {hotel.stablishment_custom_address && <div>{hotel.stablishment_custom_address}</div>}
      {hotel.phone_1?.number && (
        <Styled.PhoneContainer>
          <Styled.Phone
            whatsapp={hotel.phone_1?.is_whatsapp ?? false}
            onClick={() => {
              handlePhoneClick(hotel.phone_1);
            }}
          >
            {hotel.phone_1.number}
          </Styled.Phone>
          {hotel.phone_1?.is_whatsapp && <FaWhatsapp size={16} color={"#25d366"} style={{ marginLeft: 5 }} />}
        </Styled.PhoneContainer>
      )}
      {hotel.phone_2?.number && (
        <Styled.PhoneContainer>
          <Styled.Phone
            whatsapp={hotel.phone_2?.is_whatsapp ?? false}
            onClick={() => {
              handlePhoneClick(hotel.phone_2);
            }}
          >
            {hotel.phone_2.number}
          </Styled.Phone>
          {hotel.phone_2?.is_whatsapp && <FaWhatsapp size={16} color={"#25d366"} style={{ marginLeft: 5 }} />}
        </Styled.PhoneContainer>
      )}
    </Styled.RootContainer>
  );
};

export default HotelListItem;
