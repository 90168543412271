import StoreListItem from "@components/ui/StoreListItem";
import TopBar from "@components/ui/TopBar";
import { RootState } from "@redux/index";
import * as React from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import { Helmet } from "react-helmet";

const RootContainer = styled.div`
  max-width: 700px;
  margin-bottom: 40px; 
   
  
  
`;
const NotFound = styled.p`
  font-size: 24px;
  font-weight: bold;

  text-align: justify;
  text-justify: inter-word;
  `;

  const Explanation = styled.span`
  font-weight: 200;
  font-size: 18px;
  margin-bottom: 30px;
  max-width: 700;
  
  text-align: justify;
  text-justify: inter-word;
  
  `;

const FavoritesScreen: React.FC = () => {
  const stores = useSelector((state: RootState) => state.appSettings.favorites);
  return (
    <div>
       <Helmet>
                <meta charSet="utf-8" />
                <title>Favoritos</title>
                <link rel="canonical" href="https://modacentersantacruz.com.br/favoritos" />
            </Helmet>
      <TopBar />
      <div>
        <RootContainer className="container mt-2 mt-md-5 pb-5">
          {stores.length===0 && <><NotFound> Você ainda não adicionou favoritos </NotFound><Explanation> Salve alguma loja e poderá encontrá-la sempre aqui </Explanation></>}
          {stores.map((store) => (
            <StoreListItem store={store} />
          ))}
        </RootContainer>
      </div>
    </div>
  );
};

export default FavoritesScreen;
