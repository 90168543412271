import * as React from "react";
import styled from "styled-components";
import { Department } from "@util/interfaces";
import { Link } from "react-router-dom";

type DepartmentProps = {
  department: Department;
};

const DepartmentItemRootContainer = styled (Link)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
  text-decoration: none;
`;

const DepartmentImage = styled.img`
  width: 100%;
  border-radius: 30px;
`;

const DepartmentText = styled.span`
  margin-top: 9px;
  font-size: 18px;
  color: black;
`;

const DepartmentItem: React.FC<DepartmentProps> = ({ department }) => {
  const { name, image_url, id } = department;

  return (
    <DepartmentItemRootContainer to={`/departamento/${id}`}>
      <DepartmentImage src={image_url} />
      <DepartmentText>{name.toLowerCase()}</DepartmentText>
    </DepartmentItemRootContainer>
  );
};

export default DepartmentItem;
