import * as React from "react";
import states from "@util/states.json";
import { ExcursionFilterStateButton } from "./styled";
import Api from "@services/Api";
import { ExcursionsPerState } from "@util/interfaces";
import { toastError } from "@util/functions";
import { FaBus } from "react-icons/fa";
import { useHistory } from "react-router-dom";



const ExcursionStatesFilter: React.FC = () => {
  const history = useHistory();
  const [excursionsAmount, setExcursionsAmount] = React.useState<ExcursionsPerState[]>([]);

  const fetchExcursionsPerState = async () => {
    try {
      const { data } = await Api.get(`/excursion/amount_per_state`);
      setExcursionsAmount(data.excursionsPerState);
    } catch (error) {
      toastError(error);
    }
  };

  React.useEffect(() => {
    fetchExcursionsPerState();
  }, []);

  return (
    <div style={{ marginTop: 20 }}>
      <div style={{ display: "flex", justifyContent: "center", marginBottom: 20 }}>
        <span>ou selecione um estado abaixo</span>
      </div>
      {states.map((state) =>
        excursionsAmount.find((excursion) => excursion?.state === state.short_name) ? (
          <div
            key = {state.short_name}
            onClick={() => {
              history.push(`/excursoes?estado=${state.short_name}`);
            }}
          >
            <ExcursionFilterStateButton>
              <span style={{ fontSize: 18 }}>
                {state.full_name} (<span className="fw-bold">{excursionsAmount.find((excursion) => excursion?.state === state.short_name)?.amount}</span>{" "}
                {<FaBus color="#9841e2" size={14} />})
              </span>
            </ExcursionFilterStateButton>
          </div>
        ) : null
      )}
    </div>
  );
};

export default ExcursionStatesFilter;
