import TopBar from "@components/ui/TopBar";
import * as React from "react";
import { Helmet } from "react-helmet";
import styled from "styled-components";

const RootContainer = styled.div`
  max-width: 700px;
`;

const QuestionText = styled.div`
  font-size: 32px;
  font-weight: bold;
`;

const AnswerText = styled.div`
  font-weight: 200;
  font-size: 18px;
  margin-bottom: 30px;
  max-width: 700;
  text-align: justify;
  text-justify: inter-word;
`;

const PageTitle = styled.span`
  font-size: 32px;
  display: block;
  font-weight: bold;
`;

const SacScreen: React.FC = () => {
  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Perguntas Frequentes</title>
        <link rel="canonical" href="https://modacentersantacruz.com.br/faq" />
      </Helmet>
      <TopBar />
      <RootContainer className="container mt-5 pb-5 mb-5">
        <div className="text-center mb-5">
          <PageTitle>Perguntas frequentes</PageTitle>
        </div>
        <QuestionText>Quais os dias de funcionamento do Moda Center?</QuestionText>
        <AnswerText>
          As feiras no Moda Center acontecem às sextas-feiras, das 5h às 18h. Várias marcas estendem o seu funcionamento para os demais dias da semana. Para saber o que
          abre e o que fecha de domingo a domingo no centro de compras, baixe o Aplicativo Moda Center.
        </AnswerText>
        <QuestionText>Quantas lojas há no Moda Center?</QuestionText>
        <AnswerText>
          O Moda Center Santa Cruz é o maior centro atacadista de confecções do Brasil. Ele reúne mais de 10 mil pontos comerciais, entre boxes e lojas.
        </AnswerText>
      </RootContainer>
    </div>
  );
};

export default SacScreen;
