import * as React from "react";

import { AiOutlineHeart } from "react-icons/ai";
import { RootState } from "@redux/index";
import { useAppDispatch } from "@redux/index";
import { addFavorite, removeFavorite } from "@redux/AppData.store";
import { useSelector } from "react-redux";
import styled from "styled-components";
import { Store } from "@util/interfaces";
import { toastMessage } from "@util/functions";

const FavoriteButtonContainer = styled.div`
  :hover {
    cursor: pointer;
  }
`;

interface FavoriteButtonProps extends React.HTMLAttributes<HTMLDivElement> {
  store: Store;
  iconSize: number;
}

const FavoriteButton: React.FC<FavoriteButtonProps> = ({ store, iconSize, ...props }) => {
  const favorites = useSelector((state: RootState) => state.appSettings.favorites);
  const dispatch = useAppDispatch();

  const toggleFavorite = () => {
    if (favorites.find((s) => s.id === store.id)) {
      dispatch(removeFavorite(store));
      toastMessage("Removido dos favoritos", "success");
    } else {
      dispatch(addFavorite(store));
      toastMessage("Adicionado aos favoritos", "success");
    }
  };

  const isFavorite = favorites.find((s) => s.id === store.id) ? true : false;

  return (
    <FavoriteButtonContainer onClick={toggleFavorite} {...props}>
      <AiOutlineHeart size={iconSize} color={isFavorite ? "rgb(220,0,0)" : "rgba(0,0,0,0.2)"} />
    </FavoriteButtonContainer>
  );
};

export default FavoriteButton;
