import * as React from "react";
import Segments from "@components/ui/SegmentsList";
import StoreListItem from "@components/ui/StoreListItem";
import TopBar from "@components/ui/TopBar";
import { RootState } from "@redux/index";
import Api from "@services/Api";
import { toastError } from "@util/functions";
import { StablishmentType, Store } from "@util/interfaces";
import { useSelector } from "react-redux";
import { useLocation, useParams } from "react-router";
import styled from "styled-components";
import { Waypoint } from "react-waypoint";
import LoadingBar from "react-top-loading-bar";
import HotelListItem from "@components/ui/HotelListItem";
import { Helmet } from "react-helmet";
import { useStores } from "./hooks/stores";
import { usePageTitle } from "./hooks/departmentName";

const RootContainer = styled.div`
  max-width: 700px;
`;

type StoresScreenProps = {
  department_id?: string;
  segment_id?: string;
};

const StoresScreen: React.FC = () => {
  const { department_id, segment_id } = useParams<StoresScreenProps>();
  const searchTerm = useSelector((state: RootState) => state.appSettings.searchTerm);
  const loadingBarRef = React.useRef<any>(null);
  const location = useLocation();
  const stablishmentType = new URLSearchParams(location.search).get("stablishment_type") as StablishmentType;
  const { stores, handleGetMore, loadingStores } = useStores(searchTerm, department_id, segment_id, stablishmentType);
  const { pageTitle } = usePageTitle(department_id, segment_id, searchTerm, stablishmentType);
  React.useEffect(() => {
    if (loadingStores && loadingBarRef) {
      loadingBarRef.current?.continuousStart();
    } else if (!loadingStores && loadingBarRef) {
      loadingBarRef.current?.complete();
    }
    // eslint-disable-next-line
  }, [loadingStores, loadingBarRef]);

  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{pageTitle}</title>

        <link rel="canonical" href="https://modacentersantacruz.com.br/" />
      </Helmet>
      <LoadingBar color="#f11946" ref={loadingBarRef} shadow={true} />
      <TopBar />
      <div className="pt-1 pt-md-4 mb-5">
        {department_id && <Segments departmentId={`${department_id}`} />}
        <RootContainer className="container mt-2 mt-md-5">
          {stores.map((store) => (store.stablishment_type === "hotel" ? <HotelListItem hotel={store} key={store.id} /> : <StoreListItem store={store} key={store.id} />))}
        </RootContainer>
        {!loadingStores && <Waypoint onEnter={handleGetMore} />}
      </div>
    </div>
  );
};

export default StoresScreen;
