import { OpeningTime } from "@util/interfaces";
import moment from "@util/moment";
import * as React from "react";

interface IOpeningTimeProps {
  openingTime: OpeningTime;
}

const OpeningTimeInfo: React.FC<IOpeningTimeProps> = ({ openingTime }) => {
  return (
    <div
      style={{
        width: "100%",
        padding: 20,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <div style={{ fontWeight: "bold" }}>Horário de funcionamento em dias fora de feira</div>
      {openingTime.monday_open && openingTime.monday_close ? (
        <div>
          Seg: das {moment(openingTime.monday_open).format("HH:mm")} às {moment(openingTime.monday_close).format("HH:mm")}
        </div>
      ) : null}
      {openingTime.tuesday_open && openingTime.tuesday_close ? (
        <div>
          Ter: das {moment(openingTime.tuesday_open).format("HH:mm")} às {moment(openingTime.tuesday_close).format("HH:mm")}
        </div>
      ) : null}
      {openingTime.wednesday_open && openingTime.wednesday_close ? (
        <div>
          Qua: das {moment(openingTime.wednesday_open).format("HH:mm")} às {moment(openingTime.wednesday_close).format("HH:mm")}
        </div>
      ) : null}
      {openingTime.thursday_open && openingTime.thursday_close ? (
        <div>
          Qui: das {moment(openingTime.thursday_open).format("HH:mm")} às {moment(openingTime.thursday_close).format("HH:mm")}
        </div>
      ) : null}
      {openingTime.friday_open && openingTime.friday_close ? (
        <div>
          Sex: das {moment(openingTime.friday_open).format("HH:mm")} às {moment(openingTime.friday_close).format("HH:mm")}
        </div>
      ) : null}
      {openingTime.saturday_open && openingTime.saturday_close ? (
        <div>
          Sáb: das {moment(openingTime.saturday_open).format("HH:mm")} às {moment(openingTime.saturday_close).format("HH:mm")}
        </div>
      ) : null}
      {openingTime.sunday_open && openingTime.sunday_close ? (
        <div>
          Dom: das {moment(openingTime.sunday_open).format("HH:mm")} às {moment(openingTime.sunday_close).format("HH:mm")}
        </div>
      ) : null}
    </div>
  );
};

export default OpeningTimeInfo;
