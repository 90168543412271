import * as React from "react";
import LoadingBar from "react-top-loading-bar";
import TopBar from "@components/ui/TopBar";
import { PageTitle, RootContainer } from "./components";
import { Helmet } from "react-helmet";
import moment from "moment";
import { toastError } from "@util/functions";
import Api from "@services/Api";
import { Calendar } from "react-nice-dates";
import { ptBR } from "date-fns/locale";
import "react-nice-dates/build/style.css";

const CalendarScreen: React.FC = () => {
  const loadingBarRef = React.useRef<any>(null);

  const [isFetchingDates, setIsFetchingDates] = React.useState(false);
  const [selectedDates, setSelectedDates] = React.useState<Date[]>([]);

  const modifiers = {
    selected: (date: Date) => selectedDates.some((selectedDate) => moment(selectedDate).isSame(date, "day")),
  };

  const modifiersClassNames = {
    selected: "bg-primary text-white rounded-circle",
  };

  React.useEffect(() => {
    fetchDates();
  }, []);

  React.useEffect(() => {
    if (isFetchingDates && loadingBarRef) {
      loadingBarRef.current?.continuousStart();
    } else if (!isFetchingDates && loadingBarRef) {
      loadingBarRef.current?.complete();
    }
  }, [isFetchingDates, loadingBarRef]);

  const fetchDates = async () => {
    setIsFetchingDates(true);
    try {
      loadingBarRef.current?.continuousStart(); // Start the loading bar
      const { data } = await Api.get(`/system_settings/open_dates`);
      if (data.dates?.length > 0) {
        const fetchedDates = data.dates.map((date: string) => moment(date).utc().toDate());
        setSelectedDates(fetchedDates);
        setSelectedDates((prevSelectedDates) => [...prevSelectedDates, ...fetchedDates]);
      }
    } catch (error) {
      toastError(error);
    } finally {
      setIsFetchingDates(false);
      loadingBarRef.current?.complete(); // Complete the loading bar
    }
  };

  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Calendário das Feiras</title>
        <link rel="canonical" href="https://modacentersantacruz.com.br/calendario" />
      </Helmet>
      <LoadingBar color="#f11946" ref={loadingBarRef} shadow={true} />
      <TopBar />
      <RootContainer className="container mt-5 pb-5 mb-5">
        <div className="mb-2 text-center">
          <PageTitle>Calendário das feiras</PageTitle>
          <span>
            <b className={"d-block"}>Os dias de funcionamento aparecem em destaque no calendário abaixo.</b>
          </span>
          <span>
            <b className={"d-block"}>As feiras acontecem das 5h às 18h.</b>
          </span>
        </div>
        {isFetchingDates ? (
          <div>
            {" "}
            <LoadingBar color="#f11946" ref={loadingBarRef} shadow={true} />
          </div>
        ) : (
          <div className="d-flex flex-row  align-items-center justify-content-center">
            <div className="col-12 m-2 m-md-0">
              <Calendar modifiers={modifiers} locale={ptBR} modifiersClassNames={modifiersClassNames} />
            </div>
          </div>
        )}
      </RootContainer>
    </div>
  );
};

export default CalendarScreen;
