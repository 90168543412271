import TopBar from "@components/ui/TopBar";
import * as React from "react";
import { Helmet } from "react-helmet";
import styled from "styled-components";

const PageTitle = styled.span`
  font-size: 32px;
  display: block;
  font-weight: bold;
`;

const Paragraph = styled.p`
  font-weight: 200;
  font-size: 18px;
`;

const ParagraphTitle = styled.p`
  font-size: 32px;
  font-weight: bold;
`;

const ListItem = styled.li`
  font-weight: 200;
  font-size: 18px;
`;

const TermsScreen: React.FC = () => {
  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Termos de Uso</title>
        <link rel="canonical" href="https://modacentersantacruz.com.br/termos" />
      </Helmet>
      <TopBar />
      <div style={{ maxWidth: 700, textAlign: "justify", textJustify: "inter-word" }} className="container mt-5 pb-5">
        <div className="text-center mb-5">
          <PageTitle>Termos de uso</PageTitle>
        </div>
        <ParagraphTitle>1. Termos</ParagraphTitle>
        <Paragraph>
          Ao acessar o site <a href="https://modacentersantacruz.com.br">Moda Center</a>, concorda em cumprir estes termos de serviço, todas as leis e regulamentos
          aplicáveis ​​e concorda que é responsável pelo cumprimento de todas as leis locais aplicáveis. Se você não concordar com algum desses termos, está proibido de
          usar ou acessar este site. Os materiais contidos neste site são protegidos pelas leis de direitos autorais e marcas comerciais aplicáveis.
        </Paragraph>
        <ParagraphTitle>2. Uso de Licença</ParagraphTitle>
        <Paragraph>
          É concedida permissão para baixar temporariamente uma cópia dos materiais (informações ou software) no site Moda Center , apenas para visualização transitória
          pessoal e não comercial. Esta é a concessão de uma licença, não uma transferência de título e, sob esta licença, você não pode: 
        </Paragraph>
        <ol>
          <ListItem>modificar ou copiar os materiais;  </ListItem>
          <ListItem>usar os materiais para qualquer finalidade comercial ou para exibição pública (comercial ou não comercial);  </ListItem>
          <ListItem>tentar descompilar ou fazer engenharia reversa de qualquer software contido no site Moda Center;  </ListItem>
          <ListItem>remover quaisquer direitos autorais ou outras notações de propriedade dos materiais; ou  </ListItem>
          <ListItem>transferir os materiais para outra pessoa ou 'espelhe' os materiais em qualquer outro servidor.</ListItem>
        </ol>
        <Paragraph>
          Esta licença será automaticamente rescindida se você violar alguma dessas restrições e poderá ser rescindida por Moda Center a qualquer momento. Ao encerrar a
          visualização desses materiais ou após o término desta licença, você deve apagar todos os materiais baixados em sua posse, seja em formato eletrónico ou
          impresso.
        </Paragraph>
        <ParagraphTitle>3. Isenção de responsabilidade</ParagraphTitle>
        <ol>
          <ListItem>
            Os materiais no site da Moda Center são fornecidos 'como estão'. Moda Center não oferece garantias, expressas ou implícitas, e, por este meio, isenta e nega
            todas as outras garantias, incluindo, sem limitação, garantias implícitas ou condições de comercialização, adequação a um fim específico ou não violação de
            propriedade intelectual ou outra violação de direitos.
          </ListItem>
          <ListItem>
            Além disso, o Moda Center não garante ou faz qualquer representação relativa à precisão, aos resultados prováveis ​​ou à confiabilidade do uso dos materiais
            em seu site ou de outra forma relacionado a esses materiais ou em sites vinculados a este site.
          </ListItem>
        </ol>
        <ParagraphTitle>4. Limitações</ParagraphTitle>
        <Paragraph>
          Em nenhum caso o Moda Center ou seus fornecedores serão responsáveis ​​por quaisquer danos (incluindo, sem limitação, danos por perda de dados ou lucro ou
          devido a interrupção dos negócios) decorrentes do uso ou da incapacidade de usar os materiais em Moda Center, mesmo que Moda Center ou um representante
          autorizado da Moda Center tenha sido notificado oralmente ou por escrito da possibilidade de tais danos. Como algumas jurisdições não permitem limitações em
          garantias implícitas, ou limitações de responsabilidade por danos conseqüentes ou incidentais, essas limitações podem não se aplicar a você.
        </Paragraph>
        <ParagraphTitle>5. Precisão dos materiais</ParagraphTitle>
        <Paragraph>
          Os materiais exibidos no site da Moda Center podem incluir erros técnicos, tipográficos ou fotográficos. Moda Center não garante que qualquer material em seu
          site seja preciso, completo ou atual. Moda Center pode fazer alterações nos materiais contidos em seu site a qualquer momento, sem aviso prévio. No entanto,
          Moda Center não se compromete a atualizar os materiais.
        </Paragraph>
        <ParagraphTitle>6. Links</ParagraphTitle>
        <Paragraph>
          O Moda Center não analisou todos os sites vinculados ao seu site e não é responsável pelo conteúdo de nenhum site vinculado. A inclusão de qualquer link não
          implica endosso por Moda Center do site. O uso de qualquer site vinculado é por conta e risco do usuário.
        </Paragraph>
        <h3>Modificações</h3>
        <Paragraph>
          O Moda Center pode revisar estes termos de serviço do site a qualquer momento, sem aviso prévio. Ao usar este site, você concorda em ficar vinculado à versão
          atual desses termos de serviço.
        </Paragraph>
        <h3>Lei aplicável</h3>
        <Paragraph>
          Estes termos e condições são regidos e interpretados de acordo com as leis do Moda Center e você se submete irrevogavelmente à jurisdição exclusiva dos
          tribunais naquele estado ou localidade.
        </Paragraph>
      </div>
    </div>
  );
};

export default TermsScreen;
