import * as React from "react";
import { Provider } from "react-redux";
import { ToastContainer } from "react-toastify";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import store, { persistor } from "./redux";
import { PersistGate } from "redux-persist/integration/react";
import { isMobile } from "react-device-detect";
import { Modal } from "react-responsive-modal";
import { CookieNotice } from "react-cookienotice";

import HomeScreen from "@pages/Home";
import StoresScreen from "@pages/Stores";
import StoreScreen from "@pages/Store";
import CalendarScreen from "@pages/Calendar";
import AboutScreen from "@pages/About";
import ExcursionScreen from "@pages/Excursions";
import SacScreen from "@pages/Sac";
import FavoritesScreen from "@pages/Favorites";
import TermsScreen from "@pages/Terms";
import PrivacyScreen from "@pages/Privacy";
import Footer from "@components/Footer";

// Importing assets
import playStore from "@assets/img/playstore.png";
import appStore from "@assets/img/appstore.png";

// Importing styles
import "react-toastify/dist/ReactToastify.min.css";
import "react-confirm-alert/src/react-confirm-alert.css";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import "react-responsive-modal/styles.css";
import "react-cookienotice/dist/style.css";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import "./global.scss";
import { usePageTracking, useQuery } from "@util/hooks";
import ScrollToTop from "@components/ScrollToTop";
import OpenStoresScreen from "@pages/OpenStores";
import { PrivacyPage } from "@pages/PrivacyUpdated";

const AppRoutes: React.FC = () => {
  usePageTracking();
  useQuery();

  return (
    <>
      <Route exact path="/" component={HomeScreen} />
      <Route exact path="/departamento/:department_id" component={StoresScreen} />
      <Route exact path="/segmento/:segment_id" component={StoresScreen} />
      <Route exact path="/estabelecimentos" component={StoresScreen} />
      <Route exact path="/loja/:store_id" component={StoreScreen} />
      <Route exact path="/calendario" component={CalendarScreen} />
      <Route exact path="/calendario/horario" component={OpenStoresScreen} />
      <Route exact path="/sobre" component={AboutScreen} />
      <Route exact path="/excursoes" component={ExcursionScreen} />
      <Route exact path="/faq" component={SacScreen} />
      <Route exact path="/favoritos" component={FavoritesScreen} />
      <Route exact path="/termos" component={TermsScreen} />
      <Route exact path="/privacidade" component={PrivacyScreen} />
      <Route exact path="/privacy" component={PrivacyPage} />
    </>
  );
};

const App: React.FC = () => {
  const [open, setOpen] = React.useState(false);
  const onOpenModal = () => setOpen(true);
  const onCloseModal = () => setOpen(false);

  React.useEffect(() => {
    if (isMobile) {
      onOpenModal();
    }
  }, []);

  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <Router>
          <ScrollToTop />
          <div id="page-container">
            <div id="content-wrap">
              <Switch>
                <Route path="/" component={AppRoutes} />
              </Switch>
            </div>
            <Footer />
          </div>
        </Router>
        <ToastContainer />
        <Modal open={open} onClose={onCloseModal} center>
          <div style={{ fontSize: 18, textAlign: "center", paddingInline: 15, paddingBottom: 15, paddingTop: 15 }}>Obtenha a melhor experiência no aplicativo.</div>
          <div className="row mt-4">
            <div className="col-6">
              <a href="https://play.google.com/store/apps/details?id=br.com.modacenter.appoficial" target="_blank" rel="noreferrer noopener">
                <img src={playStore} style={{ width: "100%" }} alt="play store" />
              </a>
            </div>
            <div className="col-6">
              <a href="https://apps.apple.com/br/app/moda-center-oficial/id1583015694" target="_blank" rel="noreferrer noopener">
                <img src={appStore} style={{ width: "100%" }} alt="app store" />
              </a>
            </div>
          </div>
        </Modal>
        <CookieNotice
          acceptAllButtonLabel="Aceitar"
          backButtonLabel="Voltar"
          declineAllButtonLabel="Recusar"
          acceptButtonLabel="Aceitar"
          readMoreLabel="Saiba mais"
          readMoreLink="https://modacentersantacruz.com.br/privacidade"
          titleLabel="Este site utiliza cookies para melhorar a sua experiência"
          descriptionLabel="Ao clicar em aceitar, você autoriza o uso de cookies pelo nosso website para lhe oferecer a experiência mais relevante de navegação ao lembrar das suas preferências de cookies."
        />
      </PersistGate>
    </Provider>
  );
};

export default App;
