import { toast, TypeOptions } from "react-toastify";
import { Segment } from "./interfaces";

// As many components can trigger error, and we are not sure if all of them throws 'Error'
// we use 'any' and then filter
export function toastError(error: any, type?: TypeOptions) {
  let message: string = "Erro desconhecido";
  if (error) {
    if (error.response?.data?.userMessage) message = error.response.data.userMessage;
    else if (error.response?.data?.message) message = error.response.data.message;
    else if (error.userMessage) message = error.userMessage;
    else if (error.message) message = error.message;
    else if (typeof error === "string") message = error;
  }
  toast(message, { type });
}

export function toastMessage(message: string, type?: TypeOptions) {
  toast(message, { type });
}

export function getImgSize(imgSrc: string) {
  var newImg = new Image();

  newImg.onload = function () {
    var height = newImg.height;
    var width = newImg.width;
    return { width, height };
  };

  newImg.src = imgSrc; // this must be done AFTER setting onload
}

export function getPlanFromNumber(number: number) {
  switch (number) {
    case 1:
      return "Free";
    case 2:
      return "Standard";
    case 3:
      return "Gold";
    case 4:
      return "Platinum";
    default:
      return "Free";
  }
}

export function renameFile(originalFile: File, newName: string) {
  return new File([originalFile], newName, {
    type: originalFile.type,
    lastModified: originalFile.lastModified,
  });
}

export function castColors(color: string): string | null {
  let result = null;
  switch (color.toLowerCase().trim()) {
    case "amarelo":
      result = "yellow";
      break;
    case "laranja":
      result = "orange";
      break;
    case "branco":
      result = "white";
      break;
    case "vermelho":
      result = "red";
      break;
    case "verde":
      result = "green";
      break;
    case "azul":
      result = "blue";
      break;
    default:
      result = null;
      break;
  }
  return result;
}

export const getSegmentsString = (segments: Segment[]): string => {
  let result = "";
  const segmentsIncluded = [] as string[];

  if (segments.length <= 0) return result;
  for (let i = 0; i < segments.length && i < 4; i++) {
    const segment = segments[i];
    if (!segment) continue;
    if (segmentsIncluded.find((s) => s === segment.name)) continue;
    segmentsIncluded.push(segment.name);
    if (i === 0) {
      result = segment.name;
    } else {
      result = result + ", " + segment.name;
    }
  }
  return result;
};

// AKA Sieve of Eratosthenes
const getPrimes = (min: number, max: number) => {
  const result = Array(max + 1)
    .fill(0)
    .map((_, i) => i);
  for (let i = 2; i <= Math.sqrt(max + 1); i++) {
    for (let j = i ** 2; j < max + 1; j += i) delete result[j];
  }
  return Object.values(result.slice(Math.max(min, 2)));
};

const getRandNum = (min: number, max: number) => {
  return Math.floor(Math.random() * (max - min + 1) + min);
};

export const getRandPrime = (min: number, max: number): number => {
  const primes = getPrimes(min, max);
  return primes[getRandNum(0, primes.length - 1)];
};
