import Api from "@services/Api";
import { toastError } from "@util/functions";
import { StablishmentType } from "@util/interfaces";
import { useEffect, useState } from "react";

function getStablishmentTypeLabel(stablishmentType: StablishmentType) {
  switch (stablishmentType) {
    case StablishmentType.KIOSK:
      return "Quiosques";
    case StablishmentType.RESTAURANT:
      return "Alimentação";
    case StablishmentType.HOTEL:
      return "Hospedagem";
      default:
        return "Estabelecimentos";
  }
}

export function usePageTitle(departmentId?: string | number, segmentId?: string | number, searchTerm?: string, stablishmentType?: StablishmentType) {
  const [pageTitle, setPageTitle] = useState("");

  const determinePageTitle = async () => {
    try {
      if (searchTerm) return setPageTitle("Resultados da busca");
      if (stablishmentType) return setPageTitle(getStablishmentTypeLabel(stablishmentType));
      if (departmentId) {
        const response = await Api.get(`/department/${departmentId}`);
        setPageTitle(response.data.department.name);
      }

    } catch (error) {
      console.log(error, "fetchDepartmentName");
      toastError(error);
    }
  };

  useEffect(() => {
    determinePageTitle();
  }, [stablishmentType]);

  return { pageTitle };
}
