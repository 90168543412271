import TopBar from "@components/ui/TopBar";
import Api from "@services/Api";
import { toastError } from "@util/functions";
import { Department, Image } from "@util/interfaces";
import * as React from "react";
import DepartmentItem from "./components";
import { Helmet } from "react-helmet";
import { useWindowSize } from "@util/hooks";
import { Carousel } from "react-responsive-carousel";

const HomeScreen: React.FC = () => {
  const [departments, setDepartments] = React.useState<Department[]>([]);
  const [desktopBannerImages, setDesktopBannerImages] = React.useState<Image[]>([]);
  const [mobileBannerImages, setMobileBannerImages] = React.useState<Image[]>([]);
  const [numberOfBanners, setNumberOfBanners] = React.useState<number>(0);
  const { width } = useWindowSize();

  React.useEffect(() => {
    getDepartments();
    getBannerImages();
  }, []);

  const getBannerImages = async () => {
    try {
      const { data } = await Api.get("/app-banners");
      setDesktopBannerImages(data.images.filter((image: Image) => image.is_desktop_banner));
      setMobileBannerImages(data.images.filter((image: Image) => image.is_mobile_banner));
      setNumberOfBanners(
        width > 768 ? data.images.filter((image: Image) => image.is_desktop_banner).length : data.images.filter((image: Image) => image.is_mobile_banner).length
      );
    } catch (error) {
      toastError(error);
    }
  };

  const getDepartments = async () => {
    try {
      const { data } = await Api.get("/department");
      setDepartments(data.departments);
    } catch (error) {
      toastError(error);
    }
  };

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Moda Center - Santa Cruz do Capibaribe - PE</title>
        <link rel="canonical" href="https://modacentersantacruz.com.br/" />
      </Helmet>
      <TopBar />
      {numberOfBanners > 0 && (
        <Carousel
          autoPlay={true}
          interval={5000}
          infiniteLoop={numberOfBanners > 1}
          showIndicators={numberOfBanners > 1}
          showStatus={false}
          showThumbs={false}
          showArrows={false}
          swipeable={width < 768 ? true : false}
        >
          {width < 768
            ? mobileBannerImages.map((image, index) => (
                <div key={index} style={{ maxHeight: "300px" }} className="d-flex justify-content-center align-items-center">
                  <a href={image.clickable_url ?? "#"} target="_blank" rel="noopener noreferrer">
                    <img src={image.url} style={{ objectFit: "contain" }} className="img-fluid" alt={`banner-${index}`} />
                  </a>
                </div>
              ))
            : desktopBannerImages.map((image, index) => (
                <div key={index} style={{ maxHeight: "250px" }} className="d-flex justify-content-center align-items-center">
                  <a href={image.clickable_url ?? "#"} target="_blank" rel="noopener noreferrer" className="">
                    <img src={image.url} style={{ objectFit: "contain", height: 250 }} alt={`banner-${index}`} />
                  </a>
                </div>
              ))}
        </Carousel>
      )}
      <div className="row d-flex justify-content-center p-0 m-0">
        <div className="row p-3 mt-4">
          {departments.map((department, index) => (
            <div className="col-6 col-lg-3" key={index}>
              <DepartmentItem department={department} />
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default HomeScreen;
