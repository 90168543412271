import * as React from "react";
import styled from "styled-components";

import appStore from "@assets/img/appstore_white.png";
import playStore from "@assets/img/playstore_white.png";
import whatsapp from "@assets/img/whatsapp.svg";
import instagram from "@assets/img/instagram.svg";
import facebook from "@assets/img/facebook.svg";
import youtube from "@assets/img/youtube.svg";
import { Link } from "react-router-dom";

const RootContainer = styled.footer`
  background-color: #9841e2;
  padding: 40px 80px;
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 30rem;
  @media (max-width: 768px) {
    height: 80rem;
    padding: 40px 40px;
  }
`;

const ListTitle = styled.span`
  color: white;
  font-weight: bold;
  margin-bottom: 10px;
`;

const ListLink = styled (Link)`
  color: white;
  text-decoration: none;
  display: block;
  margin-bottom: 20px;
  :hover {
    color: white;
    text-decoration: underline;
  }
`;

const ListLinkExt = styled.a`
  color: white;
  text-decoration: none;
  display: block;
  margin-bottom: 20px;
  :hover {
    color: white;
    text-decoration: underline;
  }
`;

const Text = styled.span`
  color: white;
  display: block;
`;

const Footer: React.FC = () => {
  return (
    <RootContainer>
      <div className="row">
        <div className="col-md-3">
          <ListTitle>Navegação</ListTitle>
          <br />
          <br />
          <ListLink to="/sobre">O Moda Center</ListLink>
          <ListLink to="/calendario">Calendário das feiras</ListLink>
          <ListLink to="/excursoes">Excursões/Transportadoras</ListLink>
          <ListLink to="/estabelecimentos?stablishment_type=hotel">Hospedagem</ListLink>
          <ListLink to="/estabelecimentos?stablishment_type=restaurant">Alimentação</ListLink>
          <ListLink to="/estabelecimentos?stablishment_type=kiosk">Quiosques</ListLink>

        </div>
        <div className="col-md-3 mt-5 mt-md-0">
          <ListTitle>Ajuda e serviços</ListTitle>
          <br />
          <br />
          <ListLink to="/faq">Perguntas frequentes</ListLink>
          <ListLinkExt target="_blank" rel="noreferrer noopener" href="http://www.blogdomodacenter.com.br/">
            Blog do Moda Center
          </ListLinkExt>
          <ListLink to="/privacidade">Política de privacidade</ListLink>
          <ListLink to="/termos">Termos de uso</ListLink>
          <ListLinkExt target="_blank" rel="noreferrer noopener" href="http://f7byte.com/">
            Área do condômino
          </ListLinkExt>
          <ListLinkExt target="_blank" rel="noreferrer noopener" href="https://app.notadamoda.com/index.php?class=NfaList">
            Emitir nota fiscal avulsa
          </ListLinkExt>
        </div>
        <div className="col-md-3 mt-5 mt-md-0">
          <ListTitle>Contatos</ListTitle>
          <br />
          <br />
          <div className="d-flex flex-row align-items-center">
            <div style={{ marginRight: 10 }}>
              <a href={`https://api.whatsapp.com/send?phone=+558137591000 &text=Olá, encontrei este contato no site oficial do moda center.`} target="_blank" rel="noreferrer noopener">
                <img src={whatsapp} width={30} height={30} alt="social icon" />
              </a>
            </div>
            <div style={{ marginRight: 10 }}>
              <a href="https://www.instagram.com/modacentersantacruz/" target="_blank" rel="noreferrer noopener">
                <img src={instagram} width={30} height={30} alt="social icon" />
              </a>
            </div>
            <div style={{ marginRight: 10 }}>
              <a href="https://www.facebook.com/modacentersantacruz" target="_blank" rel="noreferrer noopener">
                <img src={facebook} width={30} height={30} alt="social icon" />
              </a>
            </div>
            <div style={{ marginRight: 10 }}>
              <a href="https://www.youtube.com/c/modacentersantacruz" target="_blank" rel="noreferrer noopener">
                <img src={youtube} width={30} height={30} alt="social icon" />
              </a>
            </div>
          </div>
          <br />
          <Text>Fone: (81) 3759-1000</Text>
          <Text>contato@modacentersantacruz.com.br</Text>
          <br />
          <Text>Av. Moda Center, SN.</Text>
          <Text>Bela Vista, Santa Cruz do Capibaribe - PE</Text>
          <Text>CEP: 55195-501</Text>
        </div>
        <div className="col-md-3 mt-5 mt-md-0">
          <ListTitle>Aplicativo oficial</ListTitle>
          <div style={{ marginTop: 20 }}>
            <a href="https://play.google.com/store/apps/details?id=br.com.modacenter.appoficial" target="_blank" rel="noreferrer noopener">
              <img src={playStore} width={190} alt="store icon" />
            </a>
          </div>
          <div style={{ marginTop: 30 }}>
            <a href="https://apps.apple.com/br/app/moda-center-oficial/id1583015694" target="_blank" rel="noreferrer noopener">
              <img src={appStore} width={190} alt="store icon" />
            </a>
          </div>
        </div>
        <div className="col-12 mt-5 text-center text-white">
          <span style={{ fontSize: 12 }}>
            Produzido por:{" "}
            <a href="https://vendemoda.com.br" target="_blank" rel="noreferrer noopener" style={{ color: "white", textDecoration: "none" }}>
              Vende Moda
            </a>
          </span>
        </div>
      </div>
    </RootContainer>
  );
};

export default Footer;
