import styled from "styled-components";

export const RootContainer = styled.div`
  max-width: 700px;
`;

export const PageTitle = styled.span`
  font-size: 32px;
  display: block;
  font-weight: bold;
`;
