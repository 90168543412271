import styled from "styled-components";

type ColorIconProps = {
  color?: string;
};

export const StoreProductImageContainer = styled.div``;

export const ColorIcon = styled.div<ColorIconProps>`
  width: 20px;
  height: 20px;
  border-radius: 20px;
  background-color: ${(props) => props.color};
`;

export const RootContainer = styled.div`
  max-width: 700px;
`;

export const InfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 15px;
  position: relative;
  top: -30px;
  @media (max-width: 768px) {
    //margin-top: 50px;
  }
`;

export const InfoContainerWrapper = styled.div`
  background-color: #dfdfdf;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 15px;
  width: 100%;
`;

export const StoreImage = styled.img`
  width: 105px;
  height: 105px;
  border-radius: 50px;
`;

export const StoreTitle = styled.span`
  font-size: 24px;
  font-weight: bold;
  margin-top: 10px;
`;

export const ButtonsContainer = styled.div`
  margin-top: 40px;
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: center;
`;
