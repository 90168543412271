import * as React from "react";

import styled from "styled-components";
import { Store } from "@util/interfaces";
import { ViewProps } from "react-device-detect";
import { BiShoppingBag } from "react-icons/bi";
import ReactGA from "react-ga";

const CatalogButtonContainer = styled.div`
  :hover {
    cursor: pointer;
  }
`;

interface CatalogButtonProps extends ViewProps {
  store: Store;
  iconSize: number;
}

const CatalogButton: React.FC<CatalogButtonProps> = ({ store, iconSize, ...props }) => {
  const handlePress = () => {
    ReactGA.event({
      category: "Link",
      action: "Click",
      label: store.name,
      value: store.id,
    });
    let url = `https://${store.catalog_code}.modacentersantacruz.com.br`;
    if (store.catalog_code === "acqualara") url += `?vendedor=7`;
    window.location.href = url;
  };

  return (
    <CatalogButtonContainer onClick={handlePress} {...props}>
      <BiShoppingBag size={iconSize} color="green" />
    </CatalogButtonContainer>
  );
};

export default CatalogButton;
