import styled from "styled-components";

export const RootContainer = styled.div`
  display: flex;
  overflow-x: auto;
`;

export const SegmentContainer = styled.a`
  margin-right: 18px;
  align-items: center;
  text-align: center;
  display: flex;
  flex-direction: column;
  color: black;
  text-decoration: none;
  :hover {
    cursor: pointer;
  }
`;

export const Image = styled.img`
  width: 61px;
  height: 61px;
  background-color: #e5e5e5;
  border-radius: 30px;
`;

export const Title = styled.span`
  font-size: 13px;
  margin-top: 5px;
`;

export const ScrollContainer = styled.div`
  display: flex;
  justify-content: center;
`;
