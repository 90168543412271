import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Store } from "@util/interfaces";
import ReactGA from "react-ga";

const app = createSlice({
  name: "app",
  initialState: {
    user: null,
    searchTerm: "",
    favorites: [] as Store[],
  },
  reducers: {
    setSearchTerm(state, action: PayloadAction<string>) {
      state.searchTerm = action.payload;
    },
    addFavorite(state, action: PayloadAction<Store>) {
      ReactGA.event({
        category: "Favorite",
        action: "Add",
        label: action.payload.name,
        value: action.payload.id,
      });
      state.favorites = [...state.favorites, action.payload];
    },
    removeFavorite(state, action: PayloadAction<Store>) {
      ReactGA.event({
        category: "Favorite",
        action: "Remove",
        label: action.payload.name,
        value: action.payload.id,
      });
      state.favorites = state.favorites.filter((favorite) => favorite.id !== action.payload.id);
    },
  },
});

export default app.reducer;

export const { setSearchTerm, addFavorite, removeFavorite } = app.actions;
