import styled from "styled-components";

interface PhoneNumberProps {
  whatsapp: boolean;
}

const RootContainer = styled.div`
  background-color: #f5f5f5;
  border-radius: 11px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 30px;
  padding: 15px;
`;

const Title = styled.div`
  color: black;
  font-size: 18px;
  font-weight: bold;
  text-decoration: none;
`;

const Phone = styled.div<PhoneNumberProps>`
  color: #25d366;
  text-decoration: ${(props) => (props.whatsapp ? "underline" : "none")};
  :hover {
    cursor: ${(props) => (props.whatsapp ? "pointer" : "auto")};
  }
`;

const PhoneContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const modules = {
  RootContainer,
  Title,
  Phone,
  PhoneContainer,
};

export default modules;
