import { AppCardType, Store } from "@util/interfaces";
import * as React from "react";
import styled from "styled-components";
import noimage from "@assets/img/noimage.jpg";
import { useHistory } from "react-router";
import FavoriteButton from "../FavoriteButton";
import { getSegmentsString } from "@util/functions";
import { Link } from "react-router-dom";
import CatalogButton from "@components/ui/StoreListItem/CatalogButton";

type StoreListItemProps = {
  store: Store;
};

const ImageContainer = styled.div`
  background-color: #dfdfdf;
  border-top-right-radius: 11px;
  border-bottom-right-radius: 11px;
  overflow: hidden;
  width: 100%;
`;

const MediumCardImage = styled.img`
  width: 55px;
  height: 55px;
  border-radius: 42px;
`;

const BigCardImage = styled.img`
  width: 110px;
  height: 110px;
  border-radius: 60px;
  object-fit: cover;
  @media (max-width: 768px) {
    width: 80px;
    height: 80px;
  }
`;

const StoreTitle = styled(Link)`
  color: black;
  font-size: 18px;
  font-weight: bold;
  text-decoration: none;
  margin-top: 10px;
`;

const StoreSegment = styled.span`
  margin-top: 5px;
  line-height: 18px;
`;

const ImagePreview = styled.img`
  object-fit: cover;
`;

const CardRootContainer = styled.div`
  background-color: #f5f5f5;
  border-radius: 11px;
  &:hover {
    cursor: pointer;
  }
`;

const BigCard: React.FC<StoreListItemProps> = ({ store }) => {
  const history = useHistory();
  const logoImageSource = store.logo_image?.url.replace("thumb_1", "thumb_3").replace("thumb_2", "thumb_3") ?? noimage;
  const previewImageSource =
    store.product_images?.[Math.floor(Math.random() * store.product_images.length)]?.url.replace("thumb_1", "thumb_2").replace("thumb_2", "thumb_3") ?? noimage;

  return (
    <CardRootContainer
      className="d-flex flex-row mt-3 align-items-center mb-5"
      style={{ height: 200 }}
      onClick={() => {
        history.push(`/loja/${store.id}`);
      }}
    >
      <div className="d-flex flex-column px-3 w-100">
        <BigCardImage src={logoImageSource} />
        <StoreTitle to={`/loja/${store.id}`}>{store.name.toUpperCase()}</StoreTitle>
        <StoreSegment>{getSegmentsString(store.segments ?? [])}</StoreSegment>
      </div>
      <div className="align-self-end m-2 flex-row d-flex align-items-center">
        {store.catalog_code ? <CatalogButton store={store} iconSize={30} style={{ marginRight: 10 }} /> : null}
        <FavoriteButton store={store} iconSize={30} />
      </div>
      <div className="d-flex" style={{ height: "100%", width: 300, minWidth: 190 }}>
        <ImageContainer>
          <ImagePreview src={previewImageSource} style={{ width: "100%", height: 200 }} alt="preview" />
        </ImageContainer>
      </div>
    </CardRootContainer>
  );
};

const MediumCard: React.FC<StoreListItemProps> = ({ store }) => {
  const history = useHistory();
  const logoImageSource = store.logo_image?.url.replace("thumb_1", "thumb_3").replace("thumb_2", "thumb_3") ?? noimage;
  const previewImageSource =
    store.product_images?.[Math.floor(Math.random() * store.product_images.length)]?.url.replace("thumb_1", "thumb_2").replace("thumb_2", "thumb_3") ?? noimage;

  return (
    <CardRootContainer
      className="d-flex flex-row mt-3 align-items-center"
      style={{ height: 93 }}
      onClick={() => {
        history.push(`/loja/${store.id}`);
      }}
    >
      <div style={{ marginLeft: 20 }}>
        <MediumCardImage src={logoImageSource} />
      </div>
      <div className="d-flex flex-column px-3 w-100">
        <StoreTitle to={`/loja/${store.id}`}>{store.name.toUpperCase()}</StoreTitle>
        <StoreSegment>{getSegmentsString(store.segments ?? [])}</StoreSegment>
      </div>

      <div className="align-self-end m-3 flex-row d-flex align-items-center">
        {store.catalog_code ? <CatalogButton store={store} iconSize={30} style={{ marginRight: 10 }} /> : null}
        <FavoriteButton store={store} iconSize={30} />
      </div>
      <div className="d-flex" style={{ height: "100%", width: 120, minWidth: 82 }}>
        <ImageContainer>
          <ImagePreview src={previewImageSource} style={{ width: "100%", height: 93 }} alt="preview" />
        </ImageContainer>
      </div>
    </CardRootContainer>
  );
};

const SmallCard: React.FC<StoreListItemProps> = ({ store }) => {
  const history = useHistory();
  return (
    <CardRootContainer
      className="d-flex flex-column justify-content-center mt-3 p-4"
      style={{ backgroundColor: "#f5f5f5", borderRadius: 11, height: 70 }}
      onClick={() => {
        history.push(`/loja/${store.id}`);
      }}
    >
      <StoreTitle to={`/loja/${store.id}`}>{store.name.toUpperCase()}</StoreTitle>
      <span>{getSegmentsString(store.segments ?? [])}</span>
    </CardRootContainer>
  );
};

const StoreListItem: React.FC<StoreListItemProps> = ({ store }) => {
  return store.plan.app_card_type === AppCardType.BIG ? (
    <BigCard store={store} />
  ) : store.plan.app_card_type === AppCardType.MEDIUM ? (
    <MediumCard store={store} />
  ) : (
    <SmallCard store={store} />
  );
};

export default React.memo(StoreListItem);
