import Api from "@services/Api";
import { Segment } from "@util/interfaces";
import * as React from "react";
import styled from "styled-components";
import { toastError } from "@util/functions";
import { useHistory } from "react-router";

interface SegmentsProps {
  departmentId: number | string;
}

const RootContainer = styled.div`
  width: auto;
  overflow-x: auto;
  overflow-y: hidden;
  white-space: nowrap;

  @media (min-width: 768px) {
    display: flex;
    justify-content: space-between;
  }
`;

const SegmentContainer = styled.a`
  margin-right: 18px;
  align-items: center;
  display: flex;
  flex-direction: column;
  color: black;
  text-decoration: none;
  :hover {
    cursor: pointer;
  }
`;

const Image = styled.img`
  width: 61px;
  height: 61px;
  background-color: #e5e5e5;
  border-radius: 30px;
`;

const Title = styled.span`
  font-size: 13px;
  margin-top: 5px;
`;

const Segments: React.FC<SegmentsProps> = ({ departmentId }) => {
  const [segments, setSegments] = React.useState<Segment[]>([]);
  const history = useHistory();

  React.useEffect(() => {
    getSegments();
    // eslint-disable-next-line
  }, []);

  const getSegments = async () => {
    try {
      const { data } = await Api.get(`/department/${departmentId}`);
      setSegments(data.department?.segments?.filter((segment: Segment) => segment.storesCount > 0));
    } catch (error) {
      toastError(error);
    }
  };

  const handlePress = (segment: Segment) => {
    history.push("/segmento/" + segment.id);
  };

  return (
    <RootContainer className="d-flex justify-content-lg-center pb-2 px-3 px-md-0 mx-0">
      {segments.map((segment) => (
        <SegmentContainer
          key={segment.id}
          onClick={() => {
            handlePress(segment);
          }}
        >
          <Image src={segment.image_url} />
          <Title>{segment.name.toLowerCase()}</Title>
        </SegmentContainer>
      ))}
    </RootContainer>
  );
};

export default Segments;
