import styled from "styled-components";

export const ExcursionTitle = styled.span`
  font-size: 18px;
  font-weight: bold;
`;

export const ExcursionPhone = styled.span`
  color: #00a849;
`;

export const ExcursionListItem = styled.div`
  background-color: #f5f5f5;
  border-radius: 11px;
  margin-bottom: 15px;
  padding: 10px;
  margin-bottom: 10px;
`;

export const ExcursionFilterStateButton = styled(ExcursionListItem)`
  &:hover {
    cursor: pointer;
  }
`;
