import { Store } from "@util/interfaces";
import { useEffect, useState } from "react";
import Api from "@services/Api";
import { toastError } from "@util/functions";
import { useLocation } from "react-router";

export function useStores(searchTerm?: string, department_id?: string, segment_id?: string, stablishment_type?: string) {
  const [stores, setStores] = useState<Store[]>([]);
  const [loadingStores, setLoadingStores] = useState(true);
  const [noMoreStores, setNoMoreStores] = useState(false);
  const [page, setPage] = useState(1);
  const location = useLocation();

  useEffect(() => {
    if (page > 1) fetchstores(searchTerm ?? "", page, stablishment_type ?? undefined, department_id ?? undefined, segment_id ?? undefined, false);
    // eslint-disable-next-line
  }, [page]);

  // Handling list scroll and getting more items
  const handleGetMore = () => {
    if (!noMoreStores) setPage(page + 1);
  };

  useEffect(() => {
    setPage(1);
    setNoMoreStores(false);
    const urlQuerySearch = new URLSearchParams(location.search).get("busca");
    const stablishmentType = new URLSearchParams(location.search).get("stablishment_type");
    fetchstores(urlQuerySearch ?? searchTerm ?? "", 1, stablishmentType ?? undefined, department_id ?? undefined, segment_id ?? undefined, true);
    // eslint-disable-next-line
  }, [location]);

  const fetchstores = async (searchTerm: string, page: number, stablishmentType?: string, department?: string | number, segment?: string | number, clear?: boolean) => {
    try {
      setLoadingStores(true);
      searchTerm = encodeURIComponent(searchTerm);
      let url = "";
      // Conditioning > 0 because search input navigates to results page sending segment id = 0, so we don't want to filter by segment
      if (department && department > 0) {
        url = encodeURI(`/department/${department}/stores?&page=${page}&searchTerm=${searchTerm}`);
      } else if (segment && segment > 0) {
        url = encodeURI(`/segment/${segment}/stores?&page=${page}&searchTerm=${searchTerm}`);
      } else {
        url = encodeURI(`/store/list_to_client?page=${page}&searchTerm=${searchTerm}`);
      }
      if (stablishmentType) url += `&stablishment_type=${stablishmentType}`;
      console.log(url, "url stores");
      const { data } = await Api.get(url);
      if (clear) {
        setStores(data.stores);
      } else {
        setStores([...stores, ...data.stores]);
      }
      if (data.stores.length < 20) setNoMoreStores(true);
      setLoadingStores(false);
    } catch (error) {
      setLoadingStores(false);
      toastError(error);
    }
  };

  return { stores, loadingStores, handleGetMore, noMoreStores };
}
