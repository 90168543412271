/* eslint-disable react-hooks/exhaustive-deps */
import moment from "@util/moment";
import * as React from "react";
import Segments from "./SegmentsList";
import { ScrollContainer } from "./SegmentsList/styled";
import Styled from "./styled";

interface OpenStoresDaySelectorProps {
  onDayChange: (day: string) => void;
  disabled?: boolean;
}

const OpenStoresDaySelector: React.FC<OpenStoresDaySelectorProps> = ({ onDayChange, disabled = false }) => {
  const [selectedDay, setSelectedDay] = React.useState<string>(moment().format("dddd").toLowerCase());

  React.useEffect(() => {
    onDayChange(selectedDay);
  }, [selectedDay]);

  return (
    <div>
      <ScrollContainer style={{ flexDirection: "row" }}>
        <Styled.DaySelectorRootContainer>
          <Styled.DaySelectorItemContainer
            disabled={disabled}
            isSelected={selectedDay === "monday"}
            onClick={() => {
              setSelectedDay("monday");
            }}
          >
            <Styled.DaySelectorItemText isSelected={selectedDay === "monday"}>Seg</Styled.DaySelectorItemText>
          </Styled.DaySelectorItemContainer>
          <Styled.DaySelectorItemContainer
            disabled={disabled}
            isSelected={selectedDay === "tuesday"}
            onClick={() => {
              setSelectedDay("tuesday");
            }}
          >
            <Styled.DaySelectorItemText isSelected={selectedDay === "tuesday"}>Ter</Styled.DaySelectorItemText>
          </Styled.DaySelectorItemContainer>
          <Styled.DaySelectorItemContainer
            disabled={disabled}
            isSelected={selectedDay === "wednesday"}
            onClick={() => {
              setSelectedDay("wednesday");
            }}
          >
            <Styled.DaySelectorItemText isSelected={selectedDay === "wednesday"}>Qua</Styled.DaySelectorItemText>
          </Styled.DaySelectorItemContainer>
          <Styled.DaySelectorItemContainer
            disabled={disabled}
            isSelected={selectedDay === "thursday"}
            onClick={() => {
              setSelectedDay("thursday");
            }}
          >
            <Styled.DaySelectorItemText isSelected={selectedDay === "thursday"}>Qui</Styled.DaySelectorItemText>
          </Styled.DaySelectorItemContainer>
          <Styled.DaySelectorItemContainer
            disabled={disabled}
            isSelected={selectedDay === "friday"}
            onClick={() => {
              setSelectedDay("friday");
            }}
          >
            <Styled.DaySelectorItemText isSelected={selectedDay === "friday"}>Sex</Styled.DaySelectorItemText>
          </Styled.DaySelectorItemContainer>
          <Styled.DaySelectorItemContainer
            disabled={disabled}
            isSelected={selectedDay === "saturday"}
            onClick={() => {
              setSelectedDay("saturday");
            }}
          >
            <Styled.DaySelectorItemText isSelected={selectedDay === "saturday"}>Sáb</Styled.DaySelectorItemText>
          </Styled.DaySelectorItemContainer>
          <Styled.DaySelectorItemContainer
            disabled={disabled}
            isSelected={selectedDay === "sunday"}
            onClick={() => {
              setSelectedDay("sunday");
            }}
          >
            <Styled.DaySelectorItemText isSelected={selectedDay === "sunday"}>Dom</Styled.DaySelectorItemText>
          </Styled.DaySelectorItemContainer>
        </Styled.DaySelectorRootContainer>
      </ScrollContainer>
      <Segments openDay={selectedDay} />
    </div>
  );
};

export default OpenStoresDaySelector;
