export enum OrderStatus {
  CREATED = "created",
  IN_ANALYSIS = "in_analysis",
  CANCELLED = "cancelled",
  REFUNDED = "refunded",
  PAID = "paid",
}

export enum AccountTypes {
  CPF = "cpf",
  CNPJ = "cnpj",
}

export enum StablishmentType {
  STORE = "store",
  KIOSK = "kiosk",
  RESTAURANT = "restaurant",
  HOTEL = "hotel",
}
export interface AppData {
  searchText: string;
  favorites: number[];
}

export interface ExcursionsPerState {
  state: string;
  amount: number;
}

export interface Excursion {
  id: number;
  name: string;
  city: string;
  address?: string;
  state: string;
  phone: string;
  is_phone_whatsapp: boolean;
  phone_whatsapp_url?: string;
  phone_2?: string;
  is_phone_2_whatsapp?: boolean;
  phone_2_whatsapp_url?: string;
  route?: string;
}

export interface Department {
  name: string;
  image_url: string;
  id: string;
}

export interface Segment {
  name: string;
  image_url: string;
  id: string;
  storesCount: number;
  department?: Department;
}

export enum AppCardType {
  SMALL = "small",
  MEDIUM = "medium",
  BIG = "big",
}

export interface Plan {
  id: number;
  name: string;
  priority: number;
  price_monthly: number;
  price_semesterly: number;
  price_yearly: number;
  allow_cover_image: boolean;
  allow_logo_image: boolean;
  allow_catalog_products: boolean;
  allow_product_images: boolean;
  product_images_limit: number;
  catalog_products_limit: number;
  app_card_type: string;
}

export interface Store {
  id: number;
  name: string;
  email: string;
  instagram: string;
  site: string;
  accepts_card: boolean;
  has_delivery: boolean;
  female: boolean;
  male: boolean;
  plan: Plan;
  tags: string[];
  is_active: boolean;
  phone_1: Phone;
  phone_2?: Phone;
  logo_image?: Image;
  cover_image?: Image;
  product_images?: Image[];
  patrimonies: Patrimony[];
  segments: Segment[];
  departments: Department[];
  stablishment_type: StablishmentType;
  stablishment_custom_address?: string;
  catalog_code?: string;
  opening_time?: OpeningTime;
  addresses: StoreAddress[];
}

export interface Patrimony {
  id: number;
  block?: string;
  module: string;
  number: string;
  address_type: string;
  street?: string;
  building_type: string;
  is_central: boolean;
  lat?: number;
  lng?: number;
}

export interface Phone {
  id: number;
  number: string;
  is_whatsapp: boolean;
}

export interface Image {
  id: number;
  url: string;
  key: string;
  is_desktop_banner: boolean;
  is_mobile_banner: boolean;
  clickable_url?: string;
}

export interface StoreAddress {
  id: number;
  block?: string;
  module: string;
  number: string;
  address_type: string;
  street?: string;
  building_type: string;
}

export interface StoreCategory {
  id: number;
  name: string;
}

export interface OpeningTime {
  monday_open?: Date;
  monday_close?: Date;
  tuesday_open?: Date;
  tuesday_close?: Date;
  wednesday_open?: Date;
  wednesday_close?: Date;
  thursday_open?: Date;
  thursday_close?: Date;
  friday_open?: Date;
  friday_close?: Date;
  saturday_open?: Date;
  saturday_close?: Date;
  sunday_open?: Date;
  sunday_close?: Date;
}

export interface Hotel extends Store {}
