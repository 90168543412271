/* eslint-disable react-hooks/exhaustive-deps */
import { RouteProps } from "react-router";
import * as React from "react";
import { Store } from "@util/interfaces";
import HotelListItem from "@components/ui/HotelListItem";
import OpenStoresDaySelector from "./DaySelector";
import moment from "@util/moment";
import imgSegunda from "@assets/img/segunda.png";
import imgDomingo from "@assets/img/domingo.png";
import { toastError } from "@util/functions";
import StoreListItem from "@components/ui/StoreListItem";
import Api from "@services/Api";
import TopBar from "@components/ui/TopBar";
import { RootContainer } from "@pages/Store/components";
import LoadingBar from "react-top-loading-bar";
import { Waypoint } from "react-waypoint";
import styled from "styled-components";
import { useWindowSize } from "@util/hooks";
import { Helmet } from "react-helmet";

const CalendarImage = styled.img`
  max-width: 640px;
  width: ${(props) => props.width};
  height: ${(props) => props.height};
`;

const MainPage = styled.div`
  justify-content: center;
  display: flex;
  flex-direction: column;
`;

const Text = styled.div`
  display: flex;
  justify-content: center;
  font-size: 32px;
`;

type OpenStoresRouteProps = RouteProps;

type Props = {
  route: OpenStoresRouteProps;
};

const OpenStoresScreen: React.FC<Props> = () => {
  const [selectedDay, setSelectedDay] = React.useState<string>(moment().format("dddd").toLowerCase());
  const [stores, setStores] = React.useState<Store[]>([]);
  const [loadingStores, setLoadingStores] = React.useState(true);
  const [noMoreStores, setNoMoreStores] = React.useState(false);
  const [page, setPage] = React.useState(1);
  const loadingBarRef = React.useRef<any>(null);

  // Listening to page change, and getting more stores
  React.useEffect(() => {
    if (page) {
      if (["sunday", "monday"].includes(selectedDay)) {
        setStores([]);
      } else {
        fetchstores();
      }
    } else {
      setPage(1);
    }
  }, [page]);

  React.useEffect(() => {
    if (page >= 1) {
      setPage(0);
      setNoMoreStores(false);
    }
  }, [selectedDay]);

  React.useEffect(() => {
    if (loadingStores && loadingBarRef) {
      loadingBarRef.current?.continuousStart();
    } else if (!loadingStores && loadingBarRef) {
      loadingBarRef.current?.complete();
    }
    // eslint-disable-next-line
  }, [loadingStores, loadingBarRef]);

  // Handling list scroll and getting more items
  const handelGetMore = () => {
    if (!noMoreStores) setPage(page + 1);
  };

  const fetchstores = async () => {
    try {
      setLoadingStores(true);
      const url = encodeURI(`/store/list_to_client?page=${page}&open_day=${selectedDay}`);
      const { data } = await Api.get(url);
      setStores(page === 1 ? data.stores : [...stores, ...data.stores]);
      if (data.stores.length < 20) setNoMoreStores(true);
      setLoadingStores(false);
    } catch (error) {
      setLoadingStores(false);
      toastError(error, "error");
    }
  };

  const renderFooter = () => {
    return (
      <div>
        {loadingBarRef && stores.length > 0 ? (
          <LoadingBar color={"black"} height={3} />
        ) : noMoreStores ? (
          <div>
            <div style={{ textAlign: "center" }}>Selecione um dia no menu acima</div>
          </div>
        ) : null}
      </div>
    );
  };

  const renderListHeader = () => (
    <div>
      <OpenStoresDaySelector
        disabled={loadingStores}
        onDayChange={(day) => {
          setSelectedDay(day);
        }}
      />
      <LoadingBar color="#f11946" ref={loadingBarRef} shadow={true} />
    </div>
  );

  const window: { width: number; height: number } = useWindowSize();

  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Aberto Hoje</title>
        <link rel="canonical" href="https://modacentersantacruz.com.br/calendario/horario/" />
      </Helmet>
      <LoadingBar color="#f11946" ref={loadingBarRef} shadow={true} />
      <TopBar />
      <MainPage>
        <b>
          <Text className="mt-3 mb-3">O que está aberto</Text>
        </b>
        {renderListHeader()}
        <RootContainer className="container mt-2 mt-md-5">
          {stores.map((store) => (store.stablishment_type === "hotel" ? <HotelListItem hotel={store} key={store.id} /> : <StoreListItem store={store} key={store.id} />))}
        </RootContainer>
        <div style={{ display: "flex", justifyContent: "center" }}>
          {selectedDay === "monday" ? (
            <CalendarImage width={window.width} height={window.height} src={imgSegunda} />
          ) : selectedDay === "sunday" ? (
            <CalendarImage width={window.width} height={window.height} src={imgDomingo} />
          ) : null}
        </div>
        {!loadingStores && <Waypoint onEnter={handelGetMore} />}
        {renderFooter()}
      </MainPage>
    </div>
  );
};

export default OpenStoresScreen;
