import TopBar from "@components/ui/TopBar";
import Api from "@services/Api";
import { castColors, getSegmentsString, toastError } from "@util/functions";
import { Patrimony, Store } from "@util/interfaces";
import * as React from "react";
import { useParams } from "react-router";
import LoadingBar from "react-top-loading-bar";
import { FaInstagram, FaWhatsapp } from "react-icons/fa";
import { AiOutlineGlobal } from "react-icons/ai";
import { ButtonsContainer, ColorIcon, InfoContainer, InfoContainerWrapper, RootContainer, StoreImage, StoreProductImageContainer, StoreTitle } from "./components";
import { SRLWrapper } from "simple-react-lightbox";
import OpeningTimeInfo from "./OpeningTimeInfo";
import styled from "styled-components";
import FavoriteButton from "@components/ui/FavoriteButton";
import CatalogButton from "@components/ui/StoreListItem/CatalogButton";
import { Helmet } from "react-helmet";
import ReactGA from "react-ga";

const BackgroundCard = styled.div`
  display: flex;
  justify-content: center;
  background: rgba(190, 190, 190);
  padding: 20px;
  margin: 10px;
  border-radius: 11px;
  min-width: 265px;
`;

type StoreScreenProps = {
  store_id?: string;
};

const PatrimonyItem: React.FC<{ patrimony: Patrimony }> = ({ patrimony }) => {
  return (
    <BackgroundCard>
      Setor: {patrimony.module}
      {patrimony.address_type === "street" ? ` Rua: ${patrimony.street}` : ` Bloco: ${patrimony.block}${patrimony.is_central ? " (Central)" : ""}`},
      {patrimony.building_type === "store" ? ` Loja: ${patrimony.number}` : ` Box: ${patrimony.number}`}
    </BackgroundCard>
  );
};

const StoreScreen: React.FC = () => {
  const { store_id } = useParams<StoreScreenProps>();
  const [store, setStore] = React.useState<Store>();
  const loadingBarRef = React.useRef<any>(null);

  const whatsappPhone1 = store?.phone_1?.is_whatsapp ? store.phone_1?.number.replace(/\D+/g, "") : null;
  const whatsappPhone2 = store?.phone_2?.is_whatsapp ? store.phone_2?.number.replace(/\D+/g, "") : null;

  // Casting patrimonies to group numbers
  const patrimonies = [] as Patrimony[];
  store?.patrimonies.forEach((patrimony) => {
    const patrimoniesInSameGroup = store.patrimonies.filter(
      (p) => p.module === patrimony.module && p.street === patrimony.street && p.block === patrimony.block && p.building_type === patrimony.building_type
    );
    if (
      !patrimonies.find(
        (p) => p.module === patrimony.module && p.street === patrimony.street && p.block === patrimony.block && p.building_type === patrimony.building_type
      )
    ) {
      const orderedNumbers = patrimoniesInSameGroup.map((p) => +p.number).sort((a, b) => a - b);
      patrimonies.push({
        ...patrimony,
        number: orderedNumbers.reduce((acc, number, index, src) => acc + `${src.length > 1 && index === src.length - 1 ? " e" : index > 0 ? ", " : ""} ${number}`, ""),
      });
    }
  });

  React.useEffect(() => {
    fetchStore();
    // eslint-disable-next-line
  }, []);

  const fetchStore = async () => {
    try {
      loadingBarRef.current?.continuousStart();
      const { data } = await Api.get(`/store/id/${store_id}`);
      setStore(data.store);
      loadingBarRef.current?.complete();
    } catch (error) {
      loadingBarRef.current?.complete();
      toastError(error);
    }
  };

  const handleReactGaEvent = (eventValue: string) => {
    ReactGA.event({
      category: "Link",
      action: "Click",
      label: eventValue,
    });
  };

  const handleStoreSiteClick = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    e.preventDefault();
    if (!store?.site.startsWith("http://") && !store?.site.startsWith("https://")) {
      window.open(`https://${store?.site}`, "_blank");
    } else {
      window.open(store?.site, "_blank");
    }
  };

  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{store?.name}</title>
        <link rel="canonical" href={`https://modacentersantacruz.com.br/${store?.id}`} />
      </Helmet>
      <LoadingBar color="#f11946" ref={loadingBarRef} shadow={true} />
      <TopBar />
      {store && (
        <RootContainer className="container mt-5 pb-5">
          <InfoContainerWrapper>
            <InfoContainer>
              {store.logo_image ? <StoreImage src={store.logo_image.url} /> : <div style={{ height: 40 }} />}

              <StoreTitle>{store?.name.toUpperCase()}</StoreTitle>

              <span style={{ fontSize: 12 }}>{getSegmentsString(store.segments ?? [])}</span>
              <span className="my-3">
                {store?.phone_1?.number}
                {store?.phone_2 ? ` - ${store.phone_2.number}` : null}
              </span>

              <div style={{ flexDirection: "row", alignItems: "center" }}>
                {store.stablishment_custom_address && <BackgroundCard>{store.stablishment_custom_address}</BackgroundCard>}
                {patrimonies.map((patrimony) => (
                  <PatrimonyItem patrimony={patrimony} />
                ))}
              </div>
              {store.patrimonies?.length < 1 && store.addresses?.length > 0
                ? store.addresses.map((patrimony) => (
                    <div key={patrimony.id}>
                      <div
                        style={{
                          flexDirection: "row",
                          alignItems: "center",
                        }}
                      >
                        <span>Setor: </span>
                        <ColorIcon color={castColors(patrimony.module) ?? "blue"} style={{ marginLeft: 5 }} />
                        <span style={{ marginLeft: 5, fontSize: 16 }}>{patrimony.module}</span>
                      </div>
                      <div>
                        <span>{patrimony.address_type === "block" ? `Bloco: ${patrimony.block ?? "n/d"}` : `Rua: ${patrimony.street ?? "n/d"}`}</span>
                      </div>
                      <div>
                        <span>{patrimony.building_type === "box" ? `Box: ${patrimony.number ?? "n/d"}` : `Loja: ${patrimony.number ?? "n/d"}`}</span>
                      </div>
                    </div>
                  ))
                : null}

              <ButtonsContainer style={{ marginTop: 20 }}>
                {whatsappPhone1 ? (
                  <a
                    className="px-2 d-flex flex-column justify-content-center align-items-center"
                    style={{ color: "black", textDecoration: "none" }}
                    target="_blank"
                    rel="noreferrer noopener"
                    onClick={() => handleReactGaEvent(`message to ${whatsappPhone1}`)}
                    href={`https://api.whatsapp.com/send?phone=+55${whatsappPhone1}&text=Olá, encontrei este contato no site oficial do moda center.`}
                  >
                    <FaWhatsapp size={36} />
                    {whatsappPhone2 ? <span>whatsapp 1</span> : <span>whatsapp</span>}
                  </a>
                ) : null}
                {whatsappPhone2 ? (
                  <a
                    className="px-2 d-flex flex-column justify-content-center align-items-center"
                    style={{ color: "black", textDecoration: "none" }}
                    target="_blank"
                    rel="noreferrer noopener"
                    onClick={() => handleReactGaEvent(`message to ${whatsappPhone2}`)}
                    href={`https://api.whatsapp.com/send?phone=+55${whatsappPhone2}&text=Olá, encontrei este contato no site oficial do moda center.`}
                  >
                    <FaWhatsapp size={36} />
                    {whatsappPhone1 ? <span>whatsapp 2</span> : <span>whatsapp</span>}
                  </a>
                ) : null}
                {store?.instagram ? (
                  <a
                    className="px-2 d-flex flex-column justify-content-center align-items-center"
                    style={{ color: "black", textDecoration: "none" }}
                    target="_blank"
                    rel="noreferrer noopener"
                    onClick={() => handleReactGaEvent(store?.instagram)}
                    href={`https://instagram.com/${store?.instagram}`}
                  >
                    <FaInstagram size={36} />
                    <span>instagram</span>
                  </a>
                ) : null}
                {store?.site ? (
                  <a
                    className="px-2 d-flex flex-column justify-content-center align-items-center"
                    target="_blank"
                    rel="noreferrer noopener"
                    style={{ color: "black", textDecoration: "none" }}
                    href={store?.site}
                    onClick={handleStoreSiteClick}
                  >
                    <AiOutlineGlobal size={36} />
                    <span>site</span>
                  </a>
                ) : null}
              </ButtonsContainer>
              <div className="flex-row d-flex">
                {store.catalog_code ? (
                  <div className="m-2 d-flex flex-column justify-content-center align-items-center">
                    <CatalogButton store={store} iconSize={36} />
                    <span>Comprar no site</span>
                  </div>
                ) : null}
                <div className="m-2 d-flex flex-column justify-content-center align-items-center">
                  <FavoriteButton store={store} iconSize={36} />
                  <span>Favoritar</span>
                </div>
              </div>
              {store.opening_time ? <OpeningTimeInfo openingTime={store.opening_time} /> : null}
            </InfoContainer>
          </InfoContainerWrapper>
          <SRLWrapper>
            <div className="row mt-2">
              {store?.product_images?.map((image) => (
                <StoreProductImageContainer className="col-6 col-md-3 mt-3">
                  {/* Little 'gambiarra' here, read about the images migration to Azure */}
                  <img src={image.url?.replace("thumb_1", "thumb_3")} style={{ borderRadius: 11, width: "100%" }} alt="product" />
                </StoreProductImageContainer>
              ))}
            </div>
          </SRLWrapper>
        </RootContainer>
      )}
    </div>
  );
};

export default StoreScreen;
