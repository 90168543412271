import * as React from "react";
import styled from "styled-components";
import aboutImage from "@assets/img/mdc_aboutimage.jpg";
import TopBar from "@components/ui/TopBar";
import { Helmet } from "react-helmet";

const RootContainer = styled.div`
  max-width: 700px;
  max-width: 700;
  text-align: justify;
  text-justify: inter-word;
`;

const ContentContainer = styled.div`
  margin-top: 20px;
  padding: 18px;
`;

const DescriptionText = styled.span`
  font-size: 32px;
  display: block;
  font-weight: bold;
`;

const SmallText = styled.p`
  font-weight: 200;
  font-size: 18px;
  margin-bottom: 30px;
`;

const AboutScreen: React.FC = () => {
  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Sobre</title>
        <link rel="canonical" href="https://modacentersantacruz.com.br/sobre" />
      </Helmet>
      <TopBar />
      <RootContainer className="container mt-5 pb-5">
        <div className="mb-5 text-center">
          <DescriptionText>Sobre o Moda Center</DescriptionText>
        </div>
        <ContentContainer>
          <img src={aboutImage} style={{ borderRadius: 30, width: "100%", marginBottom: 20 }} alt="about" />
          <DescriptionText className="my-3">O Moda Center</DescriptionText>
          <SmallText>
            Localizado em Santa Cruz do Capibaribe – PE, o Moda Center é o maior centro atacadista de confecções do Brasil. São mais de 10 mil pontos comerciais, entre
            boxes e lojas.
            <br />
            <br />
            Nos períodos de maior movimentação, o local chega a receber mais de 150 mil clientes por semana, vindos de todo o país.
            <br />
            <br />
            As feiras no Moda Center acontecem às sextas-feiras, das 5h às 18h. Várias marcas estendem o seu funcionamento para os demais dias da semana. Para saber o que
            abre e o que fecha de domingo a domingo no centro de compras, baixe o Aplicativo Moda Center.
            <br />
            <br />
            O Moda Center conta com 6 praças de alimentação, estacionamento gratuito para 6 mil veículos e rede de hotéis e dormitórios com aproximadamente 2 mil leitos
            na área do complexo.
            <br />
            <br />
            Os motoristas e guias de excursão contam com alojamento gratuito, café da manhã e mais de 500 vagas exclusivas para ônibus e vans.
            <br />
            <br />A estrutura conta ainda com posto ambulatorial, farmácia, caixas eletrônicos, banheiros, carrinhos de compras, sistema de rádio e TV internos, circuito
            de segurança, balcão de informações, sala para emissão de notas fiscais, centro de eventos e muito mais.
          </SmallText>
          <DescriptionText className="my-3">Nossa missão</DescriptionText>
          <SmallText>
            Promover um ambiente de negócio no segmento de moda, integrando clientes e condôminos, consolidando-se como agente de desenvolvimento econômico e social, com
            excelência na prestação de serviços e infraestrutura.
          </SmallText>
          <DescriptionText className="my-3">Nossa visão</DescriptionText>
          <SmallText>Ser reconhecido como o maior e melhor centro atacadista de moda da América Latina.</SmallText>
        </ContentContainer>
      </RootContainer>
    </div>
  );
};

export default AboutScreen;
