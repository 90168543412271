import * as React from "react";
import { useLocation } from "react-router-dom";
import ReactGA from "react-ga";
import { useAppDispatch } from "@redux/index";
import { setSearchTerm } from "@redux/AppData.store";

export function useWindowSize() {
  // Initialize state with undefined width/height so server and client renders match
  // Learn more here: https://joshwcomeau.com/react/the-perils-of-rehydration/
  const [windowSize, setWindowSize] = React.useState({
    width: 0,
    height: 0,
  });
  React.useEffect(() => {
    // Handler to call on window resize
    function handleResize() {
      // Set window width/height to state
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    }
    // Add event listener
    window.addEventListener("resize", handleResize);
    // Call handler right away so state gets updated with initial window size
    handleResize();
    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleResize);
  }, []); // Empty array ensures that effect is only run on mount
  return windowSize;
}

export const usePageTracking = () => {
  const location = useLocation();
  const [initialized, setInitialized] = React.useState(false);

  React.useEffect(() => {
    ReactGA.initialize("UA-208554875-1");
    setInitialized(true);
  }, []);

  React.useEffect(() => {
    if (initialized) {
      ReactGA.pageview(location.pathname + location.search);
    }
  }, [initialized, location]);
};

export function useQueryParams() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}

export function useQuery() {
  const search = new URLSearchParams(useLocation().search).get("busca");
  const dispatch = useAppDispatch();

  React.useEffect(() => {
    if (search) {
      dispatch(setSearchTerm(search));
    } else {
      // Since searchTerm is persisted due to being on AppData reducer, we need to clean it when the page starts
      // in case there is no search query
      dispatch(setSearchTerm(""));
    }
    // eslint-disable-next-line
  }, [search]);

  return new URLSearchParams(useLocation().search);
}
