import * as React from "react";
import styled from "styled-components";
import Api from "@services/Api";
import { Excursion } from "@util/interfaces";
import { toastError } from "@util/functions";
import TopBar from "@components/ui/TopBar";
import { Modal } from "react-responsive-modal";
import FakeLink from "@components/ui/FakeLink";
import ExcursionStatesFilter from "./components/ExcursionStatesFilter";
import { useHistory } from "react-router-dom";
import { useQueryParams } from "@util/hooks";
import { Helmet } from "react-helmet";

const RootContainer = styled.div`
  max-width: 700px;
  text-align: justify;
  text-justify: inter-word;
`;

const ExcursionItemContainer = styled.div`
  background-color: #f5f5f5;
  border-radius: 11px;
  margin-bottom: 15px;
  padding: 10px;
  margin-top: 20px;
  display: flex;
  flex-direction: column;
`;

const ExcursionTitle = styled.span`
  font-weight: bold;
  font-size: 18px;
`;

const ExcursionPhone = styled.span`
  font-weight: bold;
  color: #00a849;
`;

const PageTitle = styled.span`
  font-size: 32px;
  display: block;
  font-weight: bold;
  word-wrap: normal;
`;

const ExcursionScreen: React.FC = () => {
  const history = useHistory();
  const queryParams = useQueryParams();
  const stateQuery = queryParams.get("estado") ?? "";
  const query = queryParams.get("busca") ?? "";

  const [searchInputValue, setSearchInputValue] = React.useState(query);
  const [excursions, setExcursions] = React.useState<Excursion[]>([]);
  const [loading, setLoading] = React.useState(false);
  const [isRouteModalOpen, setIsRouteModalOpen] = React.useState(false);
  const [selectedExcursion, setSelectedExcursion] = React.useState<Excursion>();

  const onCloseModal = () => {
    setIsRouteModalOpen(false);
    setSelectedExcursion(undefined);
  };

  React.useEffect(() => {
    if (selectedExcursion) {
      setIsRouteModalOpen(true);
    }
  }, [selectedExcursion]);

  React.useEffect(() => {
    if (query || stateQuery) fetchExcursions();
    // eslint-disable-next-line
  }, [query, stateQuery]);

  const fetchExcursions = async () => {
    try {
      setLoading(true);
      let url = `/excursion`;
      if (stateQuery) {
        url += `?state=${stateQuery}`;
      } else if (searchInputValue) {
        url += `?searchTerm=${encodeURIComponent(searchInputValue)}`;
      }
      console.log(url, "url");
      const { data } = await Api.get(url);
      setExcursions(data.excursions);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      toastError(error);
    }
  };

  const ExcursionItem: React.FC<{ excursion: Excursion }> = ({ excursion }) => {
    return (
      <ExcursionItemContainer>
        <ExcursionTitle>{excursion.name.toUpperCase()}</ExcursionTitle>
        <ExcursionPhone>
          {excursion.phone}{" "}
          {excursion.is_phone_whatsapp && excursion.phone_whatsapp_url && (
            <a href={excursion.phone_whatsapp_url} rel="noopener noreferrer" target="_blank">
              WhatsApp
            </a>
          )}
        </ExcursionPhone>
        {excursion.phone_2 ? (
          <ExcursionPhone>
            {excursion.phone_2}{" "}
            {excursion.is_phone_2_whatsapp && excursion.phone_2_whatsapp_url && (
              <a href={excursion.phone_2_whatsapp_url} rel="noopener noreferrer" target="_blank">
                WhatsApp
              </a>
            )}
          </ExcursionPhone>
        ) : null}
        <span>
          {excursion.state} - {excursion.city}
        </span>
        {excursion.address ? <span>Endereço no Moda Center: {excursion.address}</span> : null}
        {excursion.route ? (
          <FakeLink
            onClick={() => {
              setSelectedExcursion(excursion);
            }}
            className="d-flex"
          >
            Ver rotas
          </FakeLink>
        ) : null}
      </ExcursionItemContainer>
    );
  };

  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Excursões e Transportadoras</title>
        <link rel="canonical" href="https://modacentersantacruz.com.br/excursoes" />
      </Helmet>
      <TopBar />
      <RootContainer className="container mt-5 pb-5">
        <div className="mb-5 text-center">
          <PageTitle>Excursões / Transportadoras</PageTitle>
        </div>
        <form
          className="w-100 d-flex flex-row"
          onSubmit={(e) => {
            e.preventDefault();
            history.push(`/excursoes?busca=${encodeURIComponent(searchInputValue)}`);
          }}
        >
          <input
            className="form-control me-2"
            placeholder="Busque por cidade ou excursão"
            minLength={2}
            value={searchInputValue}
            onChange={(e) => setSearchInputValue(e.target.value)}
          />
          <button
            type="submit"
            className={"btn btn-primary rounded-pill"}
            onClick={() => {
              history.push(`/excursoes?busca=${encodeURIComponent(searchInputValue)}`);
            }}
          >
            Buscar
          </button>
          <button
            type="button"
            className={"btn btn-white border-primary ms-2 text-primary rounded-pill"}
            onClick={() => {
              setSearchInputValue("");
              history.push(`/excursoes`);
            }}
          >
            Limpar
          </button>
        </form>
        {loading && <div className="text-center mt-4">Carregando...</div>}
        {(stateQuery || query) && !loading ? (
          excursions.length >= 1 ? (
            excursions.map((excursion) => <ExcursionItem key={excursion.id} excursion={excursion} />)
          ) : (
            <div className="text-center mt-4">Sem resultados</div>
          )
        ) : !loading ? (
          <ExcursionStatesFilter />
        ) : null}
      </RootContainer>
      <Modal open={isRouteModalOpen} onClose={onCloseModal} center>
        <h4 style={{ minWidth: 150 }}>Rotas</h4>
        <div style={{ whiteSpace: "pre-line" }}>{selectedExcursion?.route}</div>
      </Modal>
    </div>
  );
};

export default ExcursionScreen;
