import styled from "styled-components";

interface DaySelectorItemProps {
  isSelected: boolean;
}

const DaySelectorRootContainer = styled.div`
  display: flex;
  margin-bottom: 30px;
  padding-bottom: 20px;
  overflow-x: auto;
`;

const DaySelectorItemContainer = styled.button<DaySelectorItemProps>`
  padding: 8px 30px;
  background-color: ${(props) => (props.isSelected ? "black" : "transparent")};
  border: 1px solid black;
  width: auto;
  border-radius: 8px;
  margin-left: 5px;
  margin-right: 5px;
`;

const DaySelectorItemText = styled.div<DaySelectorItemProps>`
  color: ${(props) => (props.isSelected ? "white" : "black")};
`;

const modules = {
  DaySelectorRootContainer,
  DaySelectorItemContainer,
  DaySelectorItemText,
};

export default modules;
